import { Link } from '@remix-run/react'

export function BuiltOnBase() {
  return (
    <Link
      to={'https://base.org'}
      target="_blank"
      className="text-lg m-auto items-center font-medium group text-primary/80 hover:text-primary flex flex-row gap-2"
    >
      Built on{' '}
      <svg
        viewBox="0 0 416 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-[18px]"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.034 55.017C110.034 85.402 85.359 110.034 54.921 110.034C26.0432 110.034 2.35281 87.8625 0 59.6416H72.8467V50.3923H0C2.35281 22.1714 26.0432 0 54.921 0C85.359 0 110.034 24.6319 110.034 55.017ZM314.359 100.066C333.456 100.066 345.98 89.7739 345.98 74.2731C345.98 59.8883 336.556 53.068 322.295 50.7119L309.646 48.6037C299.974 46.9917 293.525 42.7754 293.525 33.4749C293.525 24.0505 300.718 16.8581 314.359 16.8581C327.627 16.8581 334.448 23.5544 335.192 33.1029H344.74C343.996 20.4543 334.324 9.16968 314.483 9.16968C294.889 9.16968 284.101 20.2062 284.101 33.847C284.101 48.3557 293.897 55.0521 307.29 57.2842L320.063 59.2683C330.727 61.1284 336.68 65.4686 336.68 74.6451C336.68 85.4337 327.875 92.378 314.483 92.378C300.594 92.378 291.913 85.6817 291.169 74.2731H281.745C282.489 89.1539 293.897 100.066 314.359 100.066ZM173.574 98.3303H138.853V11.0298H172.334C187.091 11.0298 197.384 19.7102 197.384 33.599C197.384 43.6435 191.679 50.3398 182.503 52.5719V52.944C193.415 55.0521 200.112 62.4925 200.112 74.1491C200.112 89.1539 189.075 98.3303 173.574 98.3303ZM171.094 49.3478C181.635 49.3478 188.083 43.6435 188.083 34.715V33.4749C188.083 24.5465 181.635 18.9662 171.094 18.9662H148.153V49.3478H171.094ZM172.21 90.3939C183.743 90.3939 190.811 84.0696 190.811 74.3971V73.157C190.811 63.1125 183.619 57.0362 172.086 57.0362H148.153V90.3939H172.21ZM275.216 98.3303H265.296L257.855 74.6451H223.133L215.693 98.3303H206.268L234.914 11.0298H246.199L275.216 98.3303ZM240.99 20.5783H240.246L225.613 66.8327H255.499L240.99 20.5783ZM359.949 98.3303V11.0298H416V19.0902H369.25V49.0998H412.28V57.0362H369.25V90.2699H416V98.3303H359.949Z"
          className="fill-[#FFF]/80 group-hover:fill-[#FFF]"
        />
      </svg>
    </Link>
  )
}
